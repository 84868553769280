import React from "react";
import styled from "styled-components";

const InputSolid = styled.input`
  display: block;
  width: 100%;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid grey;

  ::-webkit-input-placeholder {
    color: #07131e;
    font-weight: 200;
    font-size: 16px;
  } 

  :-ms-input-placeholder {
    color: #07131e;
    font-weight: 200;
    font-size: 16px;
  }

  ::placeholder {
    color: #07131e;
    font-weight: 200;
    font-size: 16px;
  }

  :disabled {
    opacity:0.8;
    background: #efefef;
  }

  &.not-valid {
    border-color: red;
  }
`;

const Input = ({
  type = "text",
  placeholder,
  css,
  ...rest
}) => {
  return (
    <InputSolid
      width="100%"
      type={type}
      placeholder={placeholder}
      css={css}
      {...rest}
    />
  )
};

export default Input;
